$focused-border-color: $gray-700;

.form-group {
    position: relative;
    margin: 1.5rem 0;

    /**
     * Font size of the actual input and its placeholder.
     */
    .input-group {
        input.form-control {
            font-size: $input-group-font-size;

            &:disabled,
            &[readonly] {
                background-color: $gray-background;
            }
        }
    }

    label,
    &.has-inner-label label,
    &.has-float-label label {
        cursor: text !important;
        font-size: $form-group-label-font-size;
    }

    /**
     * Inputs with fancy labels should not display their placeholders.
     */
    &.has-float-label,
    &.has-inner-label {
        .input-group,
        .lsq-select-viewport {
            @include input-group(true);
        }
    }

    /**
     * Regular input label styles.
     */
    .basic-label {
        color: $gray-dark;
        margin-bottom: 0;
        font-size: 1rem;
        text-transform: uppercase;
        line-height: 1;
        display: inline-block;
        width: 100%;
    }

    /**
     * Regular inputs should display their placeholders.
     */
    &:not(.has-float-label):not(.has-inner-label) {
        .input-group,
        .lsq-select-viewport {
            @include input-group(false);
        }
    }

    &.has-basic-label {
        margin: 0;
        &:not(.invalid) {
            margin: 0 0 1rem 0 !important;
        }
        &:not(.has-float-label):not(.has-inner-label) {
            .input-group,
            .lsq-select-viewport {
                @include input-group(false, true);
            }
            &.select-group {
                .lsq-select-viewport {
                    .lsq-select-viewport-text {
                        line-height: 1.6rem;
                    }
                }
            }
        }
        .input-group {
            margin: 0;
            .form-control {
                font-size: $input-group-basic-font-size;
            }
            &.disable {
                color: $gray-600;
                background-color: $light-gray;
            }
        }
    }

    &.bg-transparent {
        .input-group {
            background: transparent !important;
        }
    }

    &.bg-dark {
        background: $black-background !important;
        color: $white;
        border-color: $gray-700 !important;

        &:focus {
            border-color: $green !important;
        }

        span {
            color: $white !important;
        }

        label {
            color: $white;
        }
    }

    &:not(.lsq-switch-container) label:not(.basic-label) {
        @include prefixed(transition, all 0.2s);
        position: absolute;
        z-index: 3;
    }

    &.has-inner-label {
        .input-group {
            &.lsq-select-viewport {
                .lsq-select-viewport-text {
                    height: $input-group-line-height + 0.1rem;
                    line-height: $input-group-line-height + 0.1rem;
                }
            }

            &.is-focused-or-filled {
                & + label {
                    display: inline-block;
                    font-weight: $fw-demi;
                    font-size: 1rem;
                    color: $gray-500;
                    top: 0.8rem;
                    left: $label-as-placeholder-left;
                }
            }

            &:not(.is-focused-or-filled) {
                & + label {
                    top: $label-as-placeholder-top;
                    left: $label-as-placeholder-left;
                    user-select: none;
                }

                &.currency-input-group + label {
                    left: 5rem;
                }
            }

            input,
            .lsq-select-viewport-text {
                position: relative;
                top: 0.7rem;
            }
        }
    }

    &.has-float-label {
        position: relative;
        width: 100%;

        .input-group,
        &.select-group .lsq-select-viewport &:not(textarea) {
            height: $form-control-height;
            line-height: $input-group-line-height;

            &.is-focused-or-filled {
                & + label {
                    background: $white;
                    top: -1rem;
                    cursor: text;
                    font-size: 1.1rem;
                    opacity: 1;
                    left: 1.15rem;
                    padding: 0.2rem 0.5rem;

                    &::after {
                        content: ' ';
                        display: block;
                        position: absolute;
                        background: $white;
                        height: 0rem;
                        top: 50%;
                        left: -0.2em;
                        right: -0.2em;
                        z-index: -1;
                    }
                }
            }

            &:not(.is-focused-or-filled) {
                & + label {
                    top: $label-as-placeholder-top;
                    left: 1.65rem;
                    user-select: none;
                    pointer-events: none;
                }

                &.currency-input-group + label {
                    left: 5rem;
                }
            }
        }
    }

    .input-error-message {
        color: white;
        background-color: $red;
        padding: 0.5rem;
        text-align: left !important;
    }
}

// in order to handle validation for custom input components,
// we need to access all .ng-invalid selectors under forms
// rather than just on this :host
form lsq-select.ng-touched.ng-invalid {
    .form-group {
        .input-group {
            border-color: $red !important;

            .input-group-prepend,
            .input-group-append {
                border-color: $red;
                color: $red;
            }

            + label {
                color: $red;
            }
        }

        .input-group.is-focused {
            .input-group-prepend,
            .input-group-append {
                border-color: $focused-border-color;
                color: $focused-border-color;
            }

            + label {
                color: $focused-border-color;
            }
        }
    }
}
