@use "sass:math";
$assets-path: '../';

$font-family-heading: 'IBM Plex Serif', Helvetica, Arial, sans-serif;
$font-family-paragraph: 'Muli', Helvetica, Arial, sans-serif;
$font-family: $font-family-paragraph;

// New brand.
$h1-font-family: $font-family-heading !default;
$h1-font-size: 6rem !default;
$h1-line-height: 1em !default;
$h1-font-weight: 300 !default;

$h2-font-family: $font-family-heading !default;
$h2-font-size: 5rem !default;
$h2-line-height: 1em !default;
$h2-font-weight: 300 !default;

$h3-font-family: $font-family-heading !default;
$h3-font-size: 4rem !default;
$h3-line-height: 1em !default;
$h3-font-weight: 300 !default;

$h4-font-family: $font-family-heading !default;
$h4-font-size: 3rem !default;
$h4-line-height: 1em !default;
$h4-font-weight: 300 !default;

$h5-font-family: $font-family-paragraph !default;
$h5-font-size: 3rem !default;
$h5-line-height: 1.2em !default;
$h5-font-weight: 400 !default;

$h6-font-family: $font-family-paragraph !default;
$h6-font-size: 12px !default;
$h6-line-height: 1.2em !default;
$h6-font-weight: 600 !default;

$p-font-family: $font-family-paragraph !default;
$p-line-height: 1.4em !default;
$p-font-weight: 400 !default;
$p-font-size: 1.8rem !default;
$p-font-size-larger: 2rem !default;
$p-font-size-smaller: 1.6rem !default;

$font-weight-medium: 500;

// Bootstrap Overrides: Color Palette
$blue: #007bff; // not part of the brand
$pink: #e83e8c; // not part of the brand
$orange: #fd7e14; // not part of the brand
$teal: #20c997; // not part of the brand
$cyan: #17a2b8; // not part of the brand

$indigo: #5667b1;
$purple: #9d9ae4;
$slate: #80949e;
$blue-highlight: #77a5d1;

$red-darkest: #471e17;
$red-darker: #c80a3c;
$red-dark: #bf0711;
$red: #ff5841;
$red-lighter: #fff3f4;

$yellow: #ffd146;
$yellow-highlight: #fff8e1;

$green-lightest: #eaf2f3;
$green-lighter: #93bfbf;
$green-light: #cce7df;
$green: #008661;
$green-dark: #007857;
$green-darker: darken($green, 7.5%);
$green-darkest: #366562;

// Design System 2
$dark-gray: #4d5863;
$text-gray: #647682;
$graphic-gray: #80949e;
$mid-gray: #c6cdd7;
$light-gray: #eef0f7;
$bright-gray: #f7f8fb;

$gray-50: $bright-gray;
$gray-100: $light-gray;
$gray-200: #e9ecef; // Old color system
$gray-300: #e2e5ed; // Old color system
$gray-400: #dbdee3; // Old color system
$gray-450: $mid-gray;
$gray-500: #bec8d2; // Old color system
$gray-600: #919ba5; // Old color system
$gray-650: $graphic-gray;
$gray-700: $dark-gray;
$gray-800: #000000;
$gray-900: #000000;

// Bootstrap Overrides: Renamed gray colors
$off-white: $gray-100;
$gray-lightest: $gray-200;
$gray-lighter: $gray-300;
$gray-light: $gray-400;
$gray: $gray-500;
$gray-dark: $gray-600;
$gray-darker: $gray-700;
$gray-darkest: $gray-800;

// Common Color Aliases
$transparent: transparent;
$black-background: $gray-darkest;
$black-lsq: $gray-darkest;
$gray-background: $off-white;
$gray-line: $gray-light;

$grays: (
    100: $gray-100,
    200: $gray-200,
    300: $gray-300,
    400: $gray-400,
    500: $gray-500,
    600: $gray-600,
    700: $gray-700,
    800: $gray-800,
    900: $gray-900
);

// Bootstrap Overrides: Font Sizes
$font-size-base: 1.6rem !default;

// Bootstrap Overrides: Shadows
$enable-shadows: false;
$enable-rounded: true;

// Bootstrap Overrides: Base Colors
$black: #000000;
$white: #ffffff;
$primary-dark: darken($green, 10%);
$primary: $green;
$primary-light: lighten($green, 5%);
$pink: #ff4081; // not part of the brand
$green-hover: $primary-dark;

$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600
);

// Variables: Flexbox
$start: flex-start;
$end: flex-end;
$center: center;
$stretch: stretch;
$nowrap: nowrap;
$between: space-between;
$around: space-around;

$center: center;
$wrap: wrap;
$col: column;
$row: row;
$row-reverse: row-reverse;
$col-reverse: column-reverse;

// Variables: Aliases
$right: right;
$left: left;
$top: 'top';
$bottom: 'bottom';
$border-box: border-box;
$content-box: content-box;

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true !default;
$moz: false !default;
$ms: false !default;
$o: false !default;

// Variables: Icons
$icon-svg: 'svg-icon';
$icon-png: 'png-icon';

// Variables: Typography
$fw-demi: 600;
$link-color: $green;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Variables: Layout
$navbar-height_value: 5.5;
$navbar-height: $navbar-height_value + rem;
$footer-height_value: 14;
$footer-height: $footer-height_value + rem;
$announcement-banner-height_value: 5.5;
$global-search-height-value: 9;
$global-search-height: $global-search-height-value + rem;
$global-search-height-value-mobile: 14;
$global-search-height-mobile: $global-search-height-value-mobile + rem;
$banner-min-height: 8.7rem;
$dashboard-bg: $gray-300;
$zindex-table-popover-icon: 2;
$zindex-popover-icon: 900;
$zindex-fixed: 1030;
$zindex-navigation: 1051;
$zindex-dropdown: 1050;
$zindex-modal: 1080;
$zindex-action-sheet: 1090;
$zindex-highest: 9999;
$borders: 'top', 'right', 'bottom', 'left';

// Variable: Backdrop
$default-backdrop-color: #646464;

// Variable: Container Padding
$container-padding: 6rem;
$container-padding-med: 4rem;
$container-padding-mobile: 2rem;

// Variables: Boxes
$border-radius-default: 5px;
$border-radius-button: $border-radius-default;
$border-radius-form-control: $border-radius-default;
$border-width-default: 0.2rem;
$border-radius-lg: $border-radius-default;
$box-shadow-button: 0 4px 20px rgba($black, 0.15);

// Variables: Shapes
$triangle-leg: 1.8rem;
$triangle-border-radius: $border-radius-default;
$triangle-hypotenuse: 2.55rem;
$triangle-peek: math.div($triangle-hypotenuse, 2) - 0.24rem;
$triangle-offset-top: 0.4rem;
$triangle-offset-bottom: 0.3rem;
$triangle-offset-left: 0.3rem;

// Variables: Datepicker
$datepicker-height: 20.6rem;

// Variables: Tables
$table-border-width: 0.1rem !default;
$table-border-color: $gray-450 !default;

// Variables: Popover
$popover-inner-padding: 2.4rem 1.6rem;
$popover-arrow-outer-color: $mid-gray;
$popover-arrow-outer-width: 1px;
$popover-arrow-color: white;
$popover-header-padding-y: 0rem;
$popover-header-padding-x: 0rem;
$popover-header-color: $dark-gray;
$popover-header-bg: transparent;
$popover-border-width: 1px;
$popover-border-color: $mid-gray;
$popover-border-radius: 2px;
$popover-body-padding-y: 0rem;
$popover-body-padding-x: 0rem;
$popover-body-color: $dark-gray;
$popover-body-font-size: 1.2rem;
$popover-arrow-width: 2.2rem;
$popover-arrow-height: 1.1rem;
$popover-bg: white;
$popover-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

// Variables: Sidebar
$sidebar-width: 30rem;
$sidebar-closed-width: 6.5rem;

// Variables: Sidebar Shapes
$sidebar-triangle-top: 2.5rem;
$sidebar-triangle-bottom: 0.3rem;
$sidebar-triangle-left: -1rem;

// Variables: Action Sheet
$action-sheet-top-bar-height: 5.4rem;

// Grid breakpoints
$lsq-breakpoints: (
    laptop: 1440px
);

// Variables: Form Control
$form-control-padding: 0.5rem 1rem 0.5rem 1.5rem !default;
$form-control-basic-padding: 0.5rem 1rem 0.5rem 1rem !default;
$form-control-height: 5rem !default;
$form-control-basic-height: 3.8rem !default;

// Variables: Input Group
$input-group-font-size: 1.6rem !default;
$input-group-basic-font-size: 1.3rem !default;
$input-group-line-height: 3.6rem !default;
$input-group-addon-width: 3.6rem !default;

// Variables: Form Group
$form-group-label-font-size: $input-group-font-size;

// Variables: Labels
$basic-label-size: 1.1rem;
$label-as-placeholder-top: 1.3rem !default;
$label-as-placeholder-left: 1.65rem !default;

// Variables: LsqInput
$lsq-input-default-border-color: $gray-dark !default;
$lsq-input-focused-border-color: $gray-700 !default;
$input-color: $black !default;
$input-focus-color: $black !default;
$input-placeholder-color: $gray-darker !default;
$lsq-input-border-radius: $border-radius-form-control !default;
$lsq-input-addon-height: 4.5rem !default;
$lsq-input-border-width: 0.15rem !default;

// Variables: LsqTextarea
$lsq-textarea-has-inner-label-padding-top: 2.5rem !default;
$lsq-textarea-label-rows-2-padding-top: 2.4rem !default;
$lsq-textarea-lsq-input-group-label-padding-top: 1.5rem !default;

// Typography variables
// Overrides to make the new brand typography more dashboard-friendly.
$h1-font-size: 4rem;
$h2-font-size: 3rem;
$h3-font-size: 2rem;
$h4-font-size: 1.3rem;
$h5-font-size: 1.1rem;
$h6-font-size: 1rem;
$h3-font-family: $font-family-paragraph;
$h4-font-family: $font-family-paragraph;
$h4-font-weight: bold;
$headings-font-family: $font-family-heading;
$headings-margin-bottom: 0;

$line-heights: (
    h1: 4.6rem,
    h2: 3.9rem,
    h3: 2.6rem,
    h4: 2.1rem,
    h5: 1.3rem,
    h6: 1.3rem
);

$font-sizes: (
    h1: $h1-font-size,
    h2: $h2-font-size,
    h3: $h3-font-size,
    h4: $h4-font-size,
    h5: $h5-font-size,
    h6: $h6-font-size
);

// Variables: Easing Functions
$ease-out-quart: cubic-bezier(0.25, 1, 0.5, 1);
